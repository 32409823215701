<template>
    <div class="order">
        <p class="title">
            <span>
                <span
                    class=" iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
                    style="color:#2370EB;margin:0 14px 0 30px"
                ></span>
                订单
            </span>
            <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="onAdd()"
                >添加订单</el-button
            >
        </p>
        <div class="order-box">
            <div class="order-list" v-for="(item, index) in arr">
                <div class="list-title">
                    <p class="index">订单{{ index + 1 }}</p>
                    <el-button
                        type="primary"
                        size="mini"
                        v-show="arr.length > 1"
                        @click="removeDomain(item)"
                        >删除</el-button
                    >
                </div>
                <el-form
                    style="flex:1;margin-top:20px;margin-left: 40px;margin-bottom:80px"
                    label-position="left"
                    label-width="100px"
                >
                    <el-form-item
                        label="产品名称"
                        required
                        style="margin-left:-10px"
                    >
                        <el-select
                            v-model="item.productCategory"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option
                                label="腾讯企业邮箱"
                                value="1"
                            ></el-option>
                            <el-option
                                label="阿里企业邮箱"
                                value="2"
                            ></el-option>
                            <el-option label="云产品" value="3"></el-option>
                            <el-option
                                label="网易企业邮箱"
                                value="4"
                            ></el-option>
                            <el-option label="网站" value="5"></el-option>
                            <el-option label="投屏盒子" value="6"></el-option>
                            <el-option label="微盘" value="7"></el-option>
                            <el-option label="主机" value="8"></el-option>
                            <el-option label="服务器" value="9"></el-option>
                            <el-option label="会话存档" value="10"></el-option>
                            <el-option label="企业微信" value="11"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="域名"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2
                        "
                    >
                        <el-input
                            v-model="item.domain"
                            style="margin-left:10px"
                            size="small"
                            placeholder="请输入域名"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="corpid"
                        required
                        style="margin-left:-10px"
                        v-if="item.productCategory == 1"
                    >
                        <el-input
                            v-model="item.corpId"
                            style="margin-left:10px"
                            size="small"
                            placeholder="请输入corpid"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="版本"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2
                        "
                    >
                        <el-select
                            v-if="item.productCategory == 1"
                            v-model="item.version"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="老版" value="1"></el-option>
                            <el-option label="新版" value="2"></el-option>
                        </el-select>
                        <el-select
                            v-if="item.productCategory == 2"
                            v-model="item.version"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="标准版" value="1"></el-option>
                            <el-option label="尊享版" value="2"></el-option>
                            <el-option label="集团版" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="订单类型"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 3 ||
                                item.productCategory == 10
                        "
                    >
                        <el-select
                            v-if="
                                item.productCategory == 1 ||
                                    item.productCategory == 2 ||
                                    item.productCategory == 10
                            "
                            v-model="item.orderType"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                            @change="changeType(item)"
                        >
                            <el-option label="新增" value="1"></el-option>
                            <el-option label="续费" value="2"></el-option>
                            <el-option label="扩用户" value="3"></el-option>
                        </el-select>
                        <el-select
                            v-if="item.productCategory == 3"
                            v-model="item.orderType"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="腾讯云" value="1"></el-option>
                            <el-option label="阿里云" value="2"></el-option>
                            <el-option label="华为云" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="用户数"
                        required
                        style="margin-left:-10px"
                        v-if="
                            item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 10
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model.number="item.orderNumber"
                            size="small"
                            placeholder="请输入用户数"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="购买年限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 10) &&
                                item.orderType != 3
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.orderTerm"
                            size="small"
                            placeholder="请输入购买年限"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="增补年限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2) &&
                                item.orderType != 3
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.supplementTerm"
                            size="small"
                            placeholder="请输入增补年限"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="下单年限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2) &&
                                item.orderType != 3
                        "
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.totalTerm"
                            size="small"
                            placeholder="请输入下单年限"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="vip账号金额"
                        required
                        style="margin-left:-10px"
                        v-if="item.productCategory == 10"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.vipAmount"
                            size="small"
                            placeholder="请输入vip账号金额"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="系统金额"
                        required
                        style="margin-left:-10px"
                        v-if="item.productCategory == 10"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.systemAmount"
                            size="small"
                            placeholder="请输入系统金额"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="订单金额"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.amount"
                            size="small"
                            placeholder="请输入订单金额"
                            clearable
                            oninput="value=value.replace(/[^\d]/g,'')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="业绩归属"
                        v-if="item.productCategory == 1 && item.orderType == 1"
                        required
                        style="margin-left:-10px"
                    >
                        <el-select
                            v-model="item.areaCategory"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="本地" value="1"></el-option>
                            <el-option label="异地" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="Boss额度"
                        v-if="item.productCategory == 1"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.jasoBossAmount"
                            size="small"
                            placeholder="请输入Boss额度"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="原价"
                        v-if="item.productCategory != 1"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.formerAmount"
                            size="small"
                            placeholder="请输入原价"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="交付状态"
                        required
                        style="margin-left:-10px"
                    >
                        <el-select
                            v-model="item.status"
                            size="small"
                            style="width:270px;margin-left:10px"
                            clearable
                            placeholder="请选择"
                        >
                            <el-option label="未交付" value="1"></el-option>
                            <el-option label="部分交付" value="2"></el-option>
                            <el-option label="已交付" value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="实收金额"
                        required
                        style="margin-left:-10px"
                    >
                        <el-input
                            style="margin-left:10px"
                            v-model="item.actualAmount"
                            size="small"
                            placeholder="请输入实收金额"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="服务期限"
                        required
                        style="margin-left:-10px"
                        v-if="
                            (item.productCategory == 1 ||
                                item.productCategory == 2 ||
                                item.productCategory == 3) &&
                                item.orderType != 3 &&
                                (item.status == 2 || item.status == 3)
                        "
                    >
                        <el-date-picker
                            v-model="serviceTime"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width:270px;margin-left:10px"
                            clearable
                            @change="changeService(index)"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item
                        label="订单到期"
                        required
                        style="margin-left:-10px"
                    >
                        <el-date-picker
                            style="width:270px;margin-left:10px"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            size="small"
                            v-model="item.expireTime"
                            type="date"
                            placeholder="选择日期"
                            @change="chen(item.expireTime)"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            v-model="item.remark"
                            size="small"
                            placeholder="请输入备注"
                            clearable
                        ></el-input>
                    </el-form-item>
                    <div
                        v-if="item.productCategory == 1 && item.orderType == 1"
                    >
                        <el-form-item label="是否注册邮箱">
                            <el-radio v-model="item.radio" label="1"
                                >是</el-radio
                            >
                            <el-radio v-model="item.radio" label="2"
                                >否</el-radio
                            >
                        </el-form-item>
                        <el-form-item label="受理人" v-if="item.radio == 1">
                            <el-select
                                style="width:270px"
                                v-model="item.acceptAdminId"
                                size="small"
                                clearable
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in acceptList"
                                    :key="item.id"
                                    :label="item.nickName"
                                    :value="item.id"
                                >
                                </el-option> </el-select
                        ></el-form-item>
                        <el-form-item label="联系人" v-if="item.radio == 1">
                            <el-select
                                style="width:270px"
                                v-model="item.contactId"
                                size="small"
                                clearable
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in contactList"
                                    :key="item.id"
                                    :label="item.contactName"
                                    :value="item.id"
                                >
                                </el-option> </el-select
                        ></el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
        <!-- 新增订单 -->
        <el-dialog
            :visible.sync="drawerAdd"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    新增订单
                </div>
            </template>
            <AddOrder @close="handleClose" ref="addorder" />
        </el-dialog>
    </div>
</template>
<script>
import {
    orderList,
    orderListAll,
    bossQuota,
} from '@/api/user/business/contract.js';
import { roleList } from '@/api/customer/customer';
import { getCustomerId } from '@/api/user/ae/customer';
import AddOrder from './addOrder.vue';
export default {
    components: { AddOrder },
    data() {
        return {
            field: '',
            arr: [{ radio: '2' }],
            drawerAdd: false,
            serviceTime: [],
            acceptList: [],
            contactList: [],
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        chen(i) {
            // console.log(i);
        },
        // 受理人
        getUserList() {
            let data = {
                param: {
                    roleTypeList: [16],
                },
            };
            roleList(data).then((res) => {
                this.acceptList = res.data;
                // console.log(this.$formDetails.state.data);
                this.getCustomer();
            });
        },
        // 客户详情
        getCustomer() {
            let data = {
                param: {
                    id: this.$formDetails.state.data.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                this.contactList = res.data.customerContactList;
            });
        },
        // 获取订单列表
        getData() {
            this.getUserList();
            let data = {
                param: {
                    contractId: this.$formDetails.state.data.id,
                },
            };
            orderList(data).then((res) => {
                // console.log(res);
                for (let i in res.data.list) {
                    res.data.list[i].corpId = '';
                    res.data.list[i].jasoBossAmount = '';
                }
                for (let j in res.data.list) {
                    if (res.data.list[j].orderType != 3) {
                        if (res.data.list[j].totalTerm) {
                            var myDate = new Date();
                            var mytime =
                                myDate.getFullYear() +
                                res.data.list[j].totalTerm; //获取当前时间
                            var month = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
                            var days = myDate.getDate(); //获取当前日(1-31)
                            if (month < 10) {
                                month = '0' + month;
                            }
                            if (days < 10) {
                                days = '0' + days;
                            }
                            res.data.list[j].expireTime =
                                mytime + '-' + month + '-' + days + ' 00:00:00';
                            // console.log(res.data.list[j].expireTime);
                        }
                    }
                }
                this.arr = res.data.list;
                if (this.arr.length > 0) {
                    for (let i in this.arr) {
                        this.arr[i].corpId = '';
                        var myDate = new Date();
                        if (this.arr[i].productCategory != 0) {
                            this.arr[i].productCategory = String(
                                this.arr[i].productCategory
                            );
                            if (this.arr[i].productCategory == 1) {
                                //接口获取corpId
                                let datas = {
                                    param: { domain: this.arr[i].domain },
                                };
                                orderListAll(datas).then((res) => {
                                    // console.log(res);
                                    if (res.data.list.length > 0) {
                                        this.arr[i].corpId =
                                            res.data.list[0].corpId;
                                    }
                                });
                                //接口获取Boss额度
                                let bossData = {
                                    param: {
                                        orderNumber: this.arr[i].orderNumber,
                                        orderTerm: this.arr[i].orderTerm,
                                    },
                                };
                                bossQuota(bossData).then((res) => {
                                    // console.log(res);
                                    if (res.data) {
                                        this.arr[i].jasoBossAmount =
                                            res.data.bossDiscountTotal;
                                    }
                                });
                            }
                        } else {
                            this.arr[i].productCategory = '';
                        }
                        if (this.arr[i].version != 0) {
                            this.arr[i].version = String(this.arr[i].version);
                        } else {
                            this.arr[i].version = '';
                        }
                        if (this.arr[i].orderType != 0) {
                            this.arr[i].orderType = String(
                                this.arr[i].orderType
                            );
                        } else {
                            this.arr[i].orderType = '';
                        }
                        if (
                            this.arr[i].orderNumber &&
                            this.arr[i].orderNumber != 0
                        ) {
                            this.arr[i].orderNumber = this.arr[i].orderNumber;
                        } else {
                            this.arr[i].orderNumber = '';
                        }
                        if (
                            this.arr[i].orderTerm &&
                            this.arr[i].orderTerm != 0
                        ) {
                            this.arr[i].orderTerm = this.arr[i].orderTerm;
                        } else {
                            this.arr[i].orderTerm = '';
                        }
                        if (
                            this.arr[i].supplementTerm &&
                            this.arr[i].supplementTerm != 0
                        ) {
                            this.arr[i].supplementTerm = this.arr[
                                i
                            ].supplementTerm;
                        } else {
                            this.arr[i].supplementTerm = '';
                        }
                        if (
                            this.arr[i].totalTerm &&
                            this.arr[i].totalTerm != 0
                        ) {
                            this.arr[i].totalTerm = this.arr[i].totalTerm;
                        } else {
                            this.arr[i].totalTerm = '';
                        }
                        if (this.arr[i].amount != '') {
                            this.arr[i].amount = this.arr[i].amount;
                        } else {
                            this.arr[i].productCategory = '';
                        }
                    }
                }
            });
        },
        changeService(i) {
            if (this.serviceTime.length > 0) {
                this.arr[i].startServiceTime = this.serviceTime[0];
                this.arr[i].endServiceTime = this.serviceTime[1];
            } else {
                this.arr[i].startServiceTime = '';
                this.arr[i].endServiceTime = '';
            }
        },
        // 订单类型
        changeType(item) {
            if (item.orderType == 3) {
                let data = {
                    param: { corpId: item.corpId },
                };
                orderListAll(data).then((res) => {
                    if (!res.data.list || res.data.list.length == 0) {
                        this.drawerAdd = true;
                    }
                });
            }
        },
        getAdd() {
            this.drawerAdd = true;
        },
        handleClose() {
            this.drawerAdd = false;
            this.getData();
        },
        onAdd() {
            this.arr.push({
                productCategory: '',
                version: '',
                orderType: '',
                orderNumber: '',
                orderTerm: '',
                supplementTerm: '',
                totalTerm: '',
                status: '',
                radio: '2',
                acceptAdminId: '',
                contactId: '',
            });
        },
        removeDomain(item) {
            var index = this.arr.indexOf(item);
            if (index !== -1) {
                this.arr.splice(index, 1);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.order {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        button {
            background: none;
            border-color: #2370eb;
            color: #2370eb;
            margin-right: 16px;
        }
    }
    .order-box {
        flex: 1;
        overflow: auto;
    }
    .order-list {
        .el-input {
            width: 270px;
        }
        .list-title {
            padding: 17px 0 0 38px;
            display: flex;
            .index {
                width: 72px;
                height: 24px;
                background: #2370eb;
                border-radius: 4px 2px 4px 2px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
                line-height: 24px;
                margin-right: 16px;
            }
            button {
                width: 72px;
                height: 24px;
                border-radius: 4px 2px 4px 2px;
                border: 1px solid #f5a623;
                line-height: 10px;
                background: none;
                color: #f5a623;
            }
        }
    }
}
</style>
