<template>
    <div class="addorder">
        <div class="form-box">
            <el-form
                style="flex:1;margin-top:20px;margin-left: 60px;margin-bottom:80px"
                label-position="left"
                label-width="100px"
            >
                <el-form-item
                    label="产品名称"
                    required
                    style="margin-left:-10px"
                >
                    <el-select
                        v-model="form.productCategory"
                        size="small"
                        style="width:270px;margin-left:10px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="腾讯企业邮箱" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="域名" required style="margin-left:-10px">
                    <el-input
                        v-model="form.domain"
                        style="width:270px;margin-left:10px"
                        size="small"
                        placeholder="请输入域名"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="corpid" required style="margin-left:-10px">
                    <el-input
                        v-model="form.corpId"
                        style="width:270px;margin-left:10px"
                        size="small"
                        placeholder="请输入corpid"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="版本" required style="margin-left:-10px">
                    <el-select
                        v-model="form.version"
                        size="small"
                        style="width:270px;margin-left:10px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="老版" value="1"></el-option>
                        <el-option label="新版" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="订单类型"
                    required
                    style="margin-left:-10px"
                >
                    <el-select
                        v-model="form.orderType"
                        size="small"
                        style="width:270px;margin-left:10px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="新增" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户数" required style="margin-left:-10px">
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model.number="form.orderNumber"
                        size="small"
                        placeholder="请输入用户数"
                        clearable
                        oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="购买年限"
                    required
                    style="margin-left:-10px"
                >
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model="form.orderTerm"
                        size="small"
                        placeholder="请输入购买年限"
                        clearable
                        oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="增补年限"
                    required
                    style="margin-left:-10px"
                >
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model="form.supplementTerm"
                        size="small"
                        placeholder="请输入增补年限"
                        clearable
                        oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="下单年限"
                    required
                    style="margin-left:-10px"
                >
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model="form.totalTerm"
                        size="small"
                        placeholder="请输入下单年限"
                        clearable
                        oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>

                <el-form-item
                    label="订单金额"
                    required
                    style="margin-left:-10px"
                >
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model="form.amount"
                        size="small"
                        placeholder="请输入订单金额"
                        clearable
                        oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="业绩归属"
                    required
                    style="margin-left:-10px"
                >
                    <el-select
                        v-model="form.areaCategory"
                        size="small"
                        style="width:270px;margin-left:10px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="本地" value="1"></el-option>
                        <el-option label="异地" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="Boss额度"
                    required
                    style="margin-left:-10px"
                >
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model="form.jasoBossAmount"
                        size="small"
                        placeholder="请输入Boss额度"
                        clearable
                    ></el-input>
                </el-form-item>

                <el-form-item
                    label="交付状态"
                    required
                    style="margin-left:-10px"
                >
                    <el-select
                        v-model="form.status"
                        size="small"
                        style="width:270px;margin-left:10px"
                        clearable
                        placeholder="请选择"
                    >
                        <el-option label="未交付" value="1"></el-option>
                        <el-option label="部分交付" value="2"></el-option>
                        <el-option label="已交付" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="实收金额"
                    required
                    style="margin-left:-10px"
                >
                    <el-input
                        style="width:270px;margin-left:10px"
                        v-model="form.actualAmount"
                        size="small"
                        placeholder="请输入实收金额"
                        clearable
                        oninput="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="服务期限"
                    required
                    style="margin-left:-10px"
                >
                    <el-date-picker
                        v-model="serviceTime"
                        size="small"
                        type="daterange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:270px;margin-left:10px"
                        clearable
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="下单时间"
                    required
                    style="margin-left:-10px"
                >
                    <el-date-picker
                        style="width:270px;margin-left:10px"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        size="small"
                        v-model="form.createTime"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="订单到期"
                    required
                    style="margin-left:-10px"
                >
                    <el-date-picker
                        style="width:270px;margin-left:10px"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        size="small"
                        v-model="form.expireTime"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        style="width:270px;"
                        v-model="form.remark"
                        size="small"
                        placeholder="请输入备注"
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                    size="mini"
                    @click="onSubmit"
                    >确定</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import { orderAdd } from '@/api/user/business/contract.js';
export default {
    data() {
        return {
            form: {},
            serviceTime: [],
        };
    },
    methods: {
        onSubmit() {
            if (!this.form.productCategory) {
                return this.$message.error('请选择产品');
            } else if (!this.form.domain) {
                return this.$message.error('请输入域名');
            } else if (!this.form.corpId) {
                return this.$message.error('请输入corpId');
            } else if (!this.form.version) {
                return this.$message.error('请选择版本');
            } else if (!this.form.orderType) {
                return this.$message.error('请选择订单类型');
            } else if (!this.form.orderNumber) {
                return this.$message.error('请输入账号数');
            } else if (!this.form.orderTerm) {
                return this.$message.error('请输入购买年限');
            } else if (!this.form.supplementTerm) {
                return this.$message.error('请输入增补年限');
            } else if (!this.form.totalTerm) {
                return this.$message.error('请输入下单年限');
            } else if (!this.form.amount || this.form.amount < 0) {
                return this.$message.error('请输入订单金额');
            } else if (!this.form.areaCategory) {
                return this.$message.error('请选择业绩归属');
            } else if (!this.form.jasoBossAmount) {
                return this.$message.error('请输入Boss额度');
            } else if (!this.form.status) {
                return this.$message.error('请选择交付状态');
            } else if (!this.form.actualAmount) {
                return this.$message.error('请输入实收金额');
            } else if (!this.serviceTime || this.serviceTime.length <= 0) {
                return this.$message.error('请选择服务期限');
            } else if (!this.form.createTime) {
                return this.$message.error('请选择下单日期');
            } else if (!this.form.expireTime) {
                return this.$message.error('请选择到期时间');
            } else {
                // console.log(this.serviceTime);
                this.form.startServiceTime = this.serviceTime[0];
                this.form.endServiceTime = this.serviceTime[1];
                this.form.notHave = 1;
                let data = {
                    param: {
                        orderDOList: [this.form],
                        contractId: this.$formDetails.state.data.id,
                        customerId: this.$formDetails.state.data.customerId,
                    },
                };
                orderAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
            this.form = {};
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.addorder {
    display: flex;
    max-height: 600px;
    position: relative;
    .form-box {
        flex: 1;
        overflow: auto;
    }
    .but {
        position: absolute;
        background: #fff;
        bottom: 0;
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
