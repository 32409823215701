<template>
    <div class="contract-detail">
        <div class="detail-box">
            <div class="left">
                <p class="title">
                    <span>
                        <span
                            class=" iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
                            style="color:#2370EB;margin:0 14px 0 30px"
                        ></span>
                        合同详情
                    </span>
                    <span></span>
                </p>
                <Information ref="information" />
            </div>
            <div class="right">
                <Order ref="order" />
            </div>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                v-show="
                    this.$formDetails.state.data.orderList &&
                        this.$formDetails.state.data.orderList.length == 0
                "
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;"
                size="mini"
                @click="onSubmit"
                >生成订单</el-button
            >
        </div>
    </div>
</template>
<script>
import Information from '../../contract/details/information.vue';
import Order from './order.vue';
import { orderAdd } from '@/api/user/business/contract.js';
import { orderListAll, bossQuota } from '@/api/user/business/contract.js';
import { workorderAdd } from '@/api/cs/workOrder.js';
export default {
    components: {
        Information,
        Order,
    },
    data() {
        return {};
    },
    methods: {
        getData() {
            this.$refs.information.getData();
            this.$refs.order.getData();
        },
        async onSubmit() {
            let orderDOList = this.$refs.order.arr;
            // console.log();
            for (let i in orderDOList) {
                if (!orderDOList[i].productCategory) {
                    return this.$message.error('请选择产品');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2) &&
                    !orderDOList[i].version
                ) {
                    return this.$message.error('请选择版本');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2) &&
                    !orderDOList[i].domain
                ) {
                    return this.$message.error('请输入域名');
                } else if (
                    orderDOList[i].productCategory == 1 &&
                    !orderDOList[i].corpId
                ) {
                    return this.$message.error('请输入corpid');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2 ||
                        orderDOList[i].productCategory == 3 ||
                        orderDOList[i].productCategory == 10) &&
                    !orderDOList[i].orderType
                ) {
                    return this.$message.error('请选择订单类型');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2 ||
                        orderDOList[i].productCategory == 10) &&
                    !orderDOList[i].orderNumber
                ) {
                    return this.$message.error('请输入账号数');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2 ||
                        orderDOList[i].productCategory == 10) &&
                    orderDOList[i].orderType != 3 &&
                    !orderDOList[i].orderTerm
                ) {
                    return this.$message.error('请输入购买年限');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2) &&
                    orderDOList[i].orderType != 3 &&
                    !orderDOList[i].supplementTerm
                ) {
                    return this.$message.error('请输入增补年限');
                } else if (
                    orderDOList[i].productCategory == 10 &&
                    orderDOList[i].orderType != 3 &&
                    !orderDOList[i].vipAmount
                ) {
                    return this.$message.error('请输入vip账号金额');
                } else if (
                    orderDOList[i].productCategory == 10 &&
                    orderDOList[i].orderType != 3 &&
                    !orderDOList[i].systemAmount
                ) {
                    return this.$message.error('请输入系统金额');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2) &&
                    orderDOList[i].orderType != 3 &&
                    !orderDOList[i].totalTerm
                ) {
                    return this.$message.error('请输入下单年限');
                } else if (
                    !orderDOList[i].amount ||
                    orderDOList[i].amount < 0
                ) {
                    return this.$message.error('请输入订单金额');
                } else if (
                    orderDOList[i].productCategory == 1 &&
                    orderDOList[i].orderType == 1 &&
                    !orderDOList[i].areaCategory
                ) {
                    return this.$message.error('请选择地区');
                } else if (
                    orderDOList[i].productCategory == 1 &&
                    !orderDOList[i].jasoBossAmount
                ) {
                    return this.$message.error('请输入boss额度');
                } else if (
                    orderDOList[i].productCategory != 1 &&
                    !orderDOList[i].formerAmount
                ) {
                    return this.$message.error('请输入原价');
                } else if (!orderDOList[i].status) {
                    return this.$message.error('请选择交付状态');
                } else if (!orderDOList[i].actualAmount) {
                    return this.$message.error('请输入实收金额');
                } else if (
                    (orderDOList[i].productCategory == 1 ||
                        orderDOList[i].productCategory == 2 ||
                        orderDOList[i].productCategory == 3) &&
                    orderDOList[i].orderType != 3 &&
                    orderDOList[i].status != 1 &&
                    !orderDOList[i].startServiceTime
                ) {
                    return this.$message.error('请选择服务期限');
                } else if (!orderDOList[i].expireTime) {
                    return this.$message.error('请选择订单到期时间');
                } else if (
                    orderDOList[i].radio == '1' &&
                    !orderDOList[i].acceptAdminId
                ) {
                    return this.$message.error('请选择受理人');
                } else if (
                    orderDOList[i].radio == '1' &&
                    !orderDOList[i].contactId
                ) {
                    return this.$message.error('请选择关联联系人');
                } else if (
                    orderDOList[i].productCategory == 1 &&
                    orderDOList[i].orderType == 3
                ) {
                    let data = {
                        param: { corpId: orderDOList[i].corpId },
                    };
                    var all = await orderListAll(data).then((res) => {
                        if (!res.data.list || res.data.list.length == 0) {
                            this.$refs.order.getAdd();
                            return false;
                        } else {
                            return true;
                        }
                    });
                    if (!all) {
                        return;
                    }
                }
                if (orderDOList[i].radio == '1') {
                    orderDOList[i].workOrderDTO = {
                        workOrderDO: {
                            category: 1,
                            title: this.$formDetails.state.data.companyName,
                            problemType: 5,
                            acceptAdminId: orderDOList[i].acceptAdminId,
                            priority: 1,
                            categoryType: 2,
                            categoryId: orderDOList[i].customerId,
                        },
                        customerContactDOList: [
                            { id: orderDOList[i].contactId },
                        ],
                    };
                }
            }

            let data = {
                param: {
                    orderDOList: orderDOList,
                    contractId: this.$formDetails.state.data.id,
                    customerId: this.$formDetails.state.data.customerId,
                },
            };
            await orderAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
            this.$formDetails.commit('emptyData');
        },
    },
    beforeRouteLeave(to, form, next) {
        this.$formDetails.commit('emptyData');
        next();
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contract-detail {
    width: 100%;
    height: 100%;
    background: #f0f2f4;
    display: flex;
    flex-direction: column;
    .detail-box {
        flex: 1;
        display: flex;
        height: calc(100% - 158px);
    }
    .left {
        width: 49.5%;
        flex: 1;
        margin-right: 16px;
        background: #fff;
        border-radius: 4px;
    }
    .right {
        width: 49.5%;
        background: #fff;
        flex: 1;
        border-radius: 4px;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #dee0e3;
        button {
            width: 90px;
        }
    }
    .title {
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        button {
            background: none;
            border-color: #2370eb;
            color: #2370eb;
            margin-right: 16px;
        }
    }
}
</style>
